import {cl} from '../../common/functions';

const inputFileValue = () => {
  let fileInputs = document.querySelectorAll(`input[type="file"]`);
  if (fileInputs.length !== 0) {
    [...fileInputs].forEach(fileInput => {
      let parentElement = fileInput.parentNode;
      if (parentElement !== null) {
        fileInput.addEventListener('change', e => {
          let value = fileInput.value.replace(/^.*[\\/]/, '');
          if (value !== null) {
            parentElement.setAttribute('title', value);
          }
        });
      }
    });
  }
};

export {inputFileValue};
