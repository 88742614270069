import {$, variables} from '../common/variables';

const coffeeCounterInit = () => {
  $('.counter').each(function() {
    $(this)
      .prop('Counter', 0)
      .animate(
        {
          Counter: $(this).text()
        },
        {
          duration: 2000,
          easing: 'swing',
          step: function(now) {
            $(this).text(Math.ceil(now) + '+');
          }
        }
      );
  });
};

export {coffeeCounterInit};
