import {$, variables} from '../common/variables';

const burgerMenuInit = () => {
  $(document).on('click', '#overlay-main-navigation', function() {
    $('.site-main__navigation').toggleClass('open');
    $('.sub-menu').toggleClass('width');
    $('body').toggleClass('overflow-hidden');
    $('.menu__burger').toggleClass('burger_active');
  });
};

export {burgerMenuInit};
