const variables = {};
variables.assetsDirectory = wp_params.stylesheet_directory; //StyleSheet directory passed in the localize script
variables.ajaxURL = wp_params.ajax_url; //Ajax url passed in the localize script
variables.root = document.documentElement; // CSS root element
variables.prefix = wp_params.theme_prefix;
variables.breakpoints = {}; // Object that will contain the breakpoints

const $ = jQuery.noConflict(); // Jquery passed in the register script
const __ = wp.i18n.__; // Translate lib passed in the register script

export {variables, $, __};
