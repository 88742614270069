import {cl} from './common/functions';
import {$, __, variables} from './common/variables';
import {burgerMenuInit} from './components/burger-menu';
import {coffeeCounterInit} from './components/coffee-counter';
import {inputFileValue} from './components/html-components/input-file';
import {inputRangeValue} from './components/html-components/input-range';
import {pictureErrorHandler} from './components/pictures-error';
import {progressBarInit} from './components/progress-bar';

/*
 * Pictures on error script
 * */
pictureErrorHandler();

/*
 * HTML5 elements behavior handle
 * */
inputRangeValue();
inputFileValue();

/*
 * Burger Menu
 * */
burgerMenuInit();

/*
 * Counter
 * */
coffeeCounterInit();

/*
 * ProgressBar
 * */
progressBarInit(63, $('.grey-progressbar .wp-block-group__inner-container'));
progressBarInit(85, $('.red-progressbar .wp-block-group__inner-container'));
progressBarInit(70, $('.blue-progressbar .wp-block-group__inner-container'));
