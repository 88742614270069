import {cl} from '../common/functions';
import {$, variables} from '../common/variables';

const pictureErrorHandler = () => {
  const imgs = document.querySelectorAll('img');
  if (imgs.length !== 0) {
    [...imgs].forEach(img => {
      img.addEventListener('error', e => {
        e.target.src = `${variables.assetsDirectory}/assets/img/placeholder.jpg`;
        e.target.srcset = `${variables.assetsDirectory}/assets/img/placeholder.jpg`;
      });
    });
  }
};

export {pictureErrorHandler};
