import {cl} from '../../common/functions';

const inputRangeValue = () => {
  let rangeInputs = document.querySelectorAll(`input[type="range"]`);
  if (rangeInputs.length !== 0) {
    [...rangeInputs].forEach(rangeInput => {
      let valueContainer = rangeInput.nextElementSibling;
      if (valueContainer !== null) {
        if (rangeInput.min !== undefined) {
          valueContainer.dataset.min = rangeInput.min;
        }
        if (rangeInput.max !== undefined) {
          valueContainer.dataset.max = rangeInput.max;
        }
        rangeInput.addEventListener('input', e => {
          let value = rangeInput.value;
          if (valueContainer !== null) {
            valueContainer.innerHTML = value;
          }
        });
      }
    });
  }
};

export {inputRangeValue};
