import {$, variables} from '../common/variables';

const progressBarInit = (percent, $element) => {
  //bar
  const progressBarWidth = (percent * $element.width()) / 100;
  $element.find('div').animate({width: progressBarWidth}, 3000);

  // percentage
  $('.percent').each(function() {
    $(this)
      .prop('Counter', 0)
      .animate(
        {
          Counter: $(this).text()
        },
        {
          duration: 3000,
          easing: 'swing',
          step: function(now) {
            $(this).text(Math.ceil(now) + '%');
          }
        }
      );
  });
};

export {progressBarInit};
